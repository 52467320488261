const MAX_ARTICLE_LENGTH = 6000; // Maximum length of the accumulated text
const MAX_CLIMB_DEPTH = 8; // Maximum depth to climb the DOM tree
const WIDGET_WRAPPER_NAME = 'oc-connect-widget';

// Helper: Check if an element contains meaningful text
const hasMeaningfulText = node => {
  const text = node.innerText || '';
  return text.trim().length > 50; // Lower threshold for broader context
};

// Helper: Clean and normalize text
const cleanText = text => text.replace(/\s+/g, ' ').trim();

// Helper: Collect text from siblings
const collectSiblingText = node => {
  const collectTextFromSiblings = (currentNode, direction) => {
    if (!currentNode) return [];

    const siblings = [];
    if (hasMeaningfulText(currentNode)) {
      siblings.push(cleanText(currentNode.innerText));
    }

    // Recursive call based on direction: 'prev' for previousElementSibling, 'next' for nextElementSibling
    const nextSibling =
      direction === 'prev'
        ? currentNode.previousElementSibling
        : currentNode.nextElementSibling;
    return siblings.concat(collectTextFromSiblings(nextSibling, direction));
  };

  const leftSiblings = collectTextFromSiblings(
    node.previousElementSibling,
    'prev'
  );
  const rightSiblings = collectTextFromSiblings(
    node.nextElementSibling,
    'next'
  );

  // Combine and return the siblings' text
  return [
    ...leftSiblings.reverse(),
    cleanText(node.innerText),
    ...rightSiblings
  ].join(' ');
};

// Helper: Traverse children for additional context
const collectChildText = node => {
  return [...node.querySelectorAll('*')]
    .filter(hasMeaningfulText)
    .map(el => cleanText(el.innerText))
    .join(' ');
};

const queryPossibleWidget = wName => {
  const widget = document.querySelector(wName);

  if (!widget) {
    // Base case: stop recursion if we've already checked 'oc-connect-widget'
    if (wName === WIDGET_WRAPPER_NAME) {
      return null; // Return null when 'oc-connect-widget' is not found
    }
    return queryPossibleWidget(WIDGET_WRAPPER_NAME);
  }
  return widget; // Return the found widget if it's not null
};

export default function captureExtendedContext(widgetName) {
  const widget = queryPossibleWidget(widgetName);

  if (!widget) {
    console.error('no widget');
    return;
  }

  // Start climbing the DOM
  let currentNode = widget.parentNode;
  let accumulatedText = '';
  let depth = 0;

  while (
    currentNode &&
    currentNode !== document.body &&
    depth < MAX_CLIMB_DEPTH
  ) {
    if (hasMeaningfulText(currentNode)) {
      accumulatedText += cleanText(currentNode.innerText) + ' ';
    }

    // Add sibling content at this level
    accumulatedText += collectSiblingText(currentNode) + ' ';

    // Move up the tree
    currentNode = currentNode.parentNode;
    depth++;
  }

  // After climbing, collect content from the largest meaningful container
  if (currentNode && currentNode !== document.body) {
    const childContent = collectChildText(currentNode);
    if (accumulatedText.length + childContent.length > MAX_ARTICLE_LENGTH) {
      accumulatedText += childContent.slice(
        0,
        MAX_ARTICLE_LENGTH - accumulatedText.length
      );
    } else {
      accumulatedText += childContent;
    }
  }

  accumulatedText = accumulatedText.slice(0, MAX_ARTICLE_LENGTH);

  return accumulatedText;
}
