let endpoint = '';

try {
  endpoint = process.env.CONTEXT_ENGINE_ARTICLE_ENDPOINT;
} catch {}

function toBinaryStr(str) {
  const encoder = new TextEncoder();
  const charCodes = encoder.encode(str);
  return String.fromCharCode(...charCodes);
}

export default async function shipContext(slotId, overrideUrl, text) {
  try {
    const articleBase64 = window.btoa(toBinaryStr(text));

    const payload = {
      slotId,
      articleBase64,
      referrer: overrideUrl || window.location.href
    };

    const req = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    if (!req.ok) {
      throw {
        name: 'Request error',
        status: req.status,
        statusText: req.statusText,
        requestText: await req.text()
      };
    }
  } catch (error) {
    error.errorOrigin = 'ArticleContextCollection';
    throw error;
  }
}
