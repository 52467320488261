import captureExtendedContext from './captureExtendedContext';
import shipContext from './shipContext';
import journeyToWidget from './journeyToWidget';

export default function ContextService({
  journey,
  scrapeArticle,
  slotId,
  overrideUrl
}) {
  if (!slotId || !journey || !scrapeArticle) {
    return;
  }

  const widgetFullname = journeyToWidget(journey);

  const payload = captureExtendedContext(widgetFullname);

  shipContext(slotId, overrideUrl, payload).catch(() => {});
}
